<template>
  <b-modal
    id="modal-close-workspace"
    title="Confirmation"
    centered
    ok-title="Close Model"
    ok-variant="danger"
    cancel-title="Dismiss"
    cancel-variant="outline-secondary"
    @ok="closeModel"
  >
    <p>
      Are you sure you wish to close the current model?
    </p>
  </b-modal>
</template>

<script>
import router from '@/router'

export default {
  name: 'CloseModelWorkspace',
  methods: {
    closeModel() {
      this.$store
        .dispatch('model/closeWorkspace')
        .then(() => {
          router.push({ name: 'model_browse' })
        })
    },
  },
}
</script>
